<template>
  <v-container class="d-flex flex-column align-start" fluid>
    <v-toolbar rounded width="100%" class="text-center">
      <v-spacer></v-spacer>
      <v-toolbar-title>Note de mises à jour</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <br>
    <v-timeline dense>
      <update-note v-for="note of updateNotes" :key="note.id" :note="note"></update-note>
    </v-timeline>

    <v-btn @click="createJSON"></v-btn>

  </v-container>
</template>

<script>
// import fs from "fs"

export default {
  name: "UpdateNotes",
  components: {
    UpdateNote: () => import("@/Components/Views/UpdateNotes/UpdateNote")
  },
  data() {
    return {
      updateNotes: [
        {
          id: 1,
          version: "1.1.0",
          date: "2021-02-18",
          title: "Merci pour vos retours !",
          text: "Vous avez été nombreux à utiliser notre nouvelle interface et nous vous en remercions chaleureusement !\n" +
                "Certains d'entre vous déplorent un petit manque de fonctionnalités. Patience ! Nous n'avons fait que vous mettre l'eau à la bouche 😉 !\n" +
                "Voici néanmoins quelques petits ajustements et fonctionnalités que nous avons jugés nécessaires pour cette première mise à l'eau.",
          notes: [
            {
              title: "Redimmensionnement des modules",
              text: "Certains d'entre vous utilisent de grands écrans pour travailler, mais nous ce n'est pas le cas, du coup nous ne nous sommes pas rendus compte que les modules étaient vraiment très grands ! Nous avons donc ajouté la possibilité de choisir la taille d'affichage des modules."
            },
            {
              title: "Modification de la taille des widgets",
              text: "Parce que tout le monde a des besoins différents, nous avons ajouté la possibilité de modifier la taille de la grille des widgets. En cliquant sur personnaliser, vous pourrez choisir le nombre de colonnes et de lignes affichées à l'écran. C'est bien mieux quand on peut s'organiser comme on veut !"
            },
            {
              title: "Suppression des tâches complétées",
              text: "Bein oui, si on a une liste d'éléments et que pour en supprimer plusieurs il faut le faire un par un, c'est pas très pratique. Du coup, vous pouvez maintenant supprimer toutes les tâches complétées. Vous pouvez également les afficher/masquer."
            },
            {
              title: "Progression des tâches",
              text: "Quand on a beaucoup de choses à faire dans une journée, la liste s'allonge, et on a parfois du mal à s'y retrouver. On a pensé à rajouter une barre de progression, c'est mieux quand on voit nos objectifs."
            },
            {
              title: "Taille de l'horloge",
              text: "C'était pas un peu gros cette horloge en haut à gauche ? Nous ça nous donnait l'impression d'être pressés par le temps. Du coup nous l'avons rendu plus discrète."
            },
            {
              title: "Affichage des tâches",
              text: "Une autre modification sur les tâches ! Parce que oui, nous portons à croire que les tâches seront un outil indispensable pour votre organisation, alors nous essayons, autant que faire se peut, de le rendre ergonomique. Nous avons simplement mis le texte en entier, ce n'était pas pratique de passer sa souris sur le texte à chaque fois pour le voir en entier."
            },
            {
              title: "Rester connecté",
              text: "Oui, c'est quand même indispensable une option pour rester connecté. Retapez son mot de passe peut être très fastidieux, surtout quand on accède des dizaines de fois par jour à un logiciel."
            },
          ],
          bugs: [
            {
              type: "bug",
              text:  "Au dela de 10 tâches créées, le comportement des actions était incertain",
            },
            {
              type: "bug",
              text: "Certains modules n'étaient pas affichés"
            },
            {
              type: "bug",
              text: "Un bon vieux problème d'encodage de caractères sur la liste des sous-modules"
            },
            {
              type: "bug",
              text: "Certains modules mis en favoris redirigeaient vers une erreur 404"
            }
          ]
        }
      ],
      trad: [
        [
          "CLEF",
          "FRANCAIS",
          "ANGLAIS",
          "ALLEMAND",
          "PORTUGAIS",
          "NEERLANDAIS",
          "CORSE",
          "ITALIEN"
        ],
        [
          "account.fields.actualPassword",
          "Mot de passe actuel",
          "Current password",
          "Aktuelles Passwort",
          "Senha atual",
          "Pasfhocal reatha",
          "parolla d'intesa attuale",
          "password attuale"
        ],
        [
          "account.fields.changePassword",
          "Changer le mot de passe",
          "Change password",
          "Passwort ändern",
          "Mudar senha",
          "Athraigh do phasfhocal",
          "Cambià Password",
          "Cambiare la password"
        ],
        [
          "account.fields.changePasswordSignature",
          "Changer le mot de passe de signature",
          "Change signature password",
          "Ändern Sie das Signaturkennwort",
          "Alterar senha de assinatura",
          "Athraigh pasfhocal sínithe",
          "Cambia a password di firma",
          "Modifica la password di firma"
        ],
        [
          "account.fields.confirmPassword",
          "Confirmation mot de passe",
          "Confirm password",
          "Passwort Bestätigung",
          "ConfirmaÇão Da Senha",
          "Deimhniú pasfhocal",
          "Cunferma di password",
          "conferma password"
        ],
        [
          "account.fields.firstname",
          "Prénom",
          "First name",
          "Pr Name",
          "Nome do Pr",
          "Ainm pr",
          "Pr nome",
          "Nome Pr"
        ],
        [
          "account.fields.lastname",
          "Nom",
          "Name",
          "Nachname",
          "Último nome",
          "Ainm deireanach",
          "Casata",
          "Nome"
        ],
        [
          "account.fields.mailAddress",
          "Adresse E-Mail",
          "Email address",
          "E-Mail-Adresse",
          "Endereço de email",
          "Seoladh ríomhphoist",
          "Indirizzu mail",
          "Indirizzo email"
        ],
        [
          "account.fields.newPassword",
          "Nouveau mot de passe",
          "New password",
          "Neues Kennwort",
          "Nova Senha",
          "Focal Faire Nua",
          "Nova Password",
          "nuova password"
        ],
        [
          "account.fields.noPhoto",
          "Aucune photo de profil",
          "No profile picture",
          "Kein Profilbild",
          "Sem foto de perfil",
          "Gan pictiúr próifíle",
          "Micca ritrattu di prufilu",
          "Nessuna immagine del profilo"
        ],
        [
          "account.fields.noSignature",
          "Aucune signature",
          "No signature",
          "Keine Unterschrift",
          "Sem assinatura",
          "Gan síniú",
          "Nisuna firma",
          "Nessuna firma"
        ],
        [
          "account.fields.noWallpaper",
          "Aucune image de fond",
          "No background picture",
          "Kein Hintergrundbild",
          "Sem imagem de fundo",
          "Gan aon íomhá cúlra",
          "Nisuna maghjina di fondu",
          "Nessuna immagine di sfondo"
        ],
        [
          "account.fields.searchFiles",
          "Recherche dans mes fichiers",
          "Search in my files",
          "Durchsuche meine Dateien",
          "Pesquise meus arquivos",
          "Cuardaigh mo chuid comhad",
          "Cerca in i mo fugliali",
          "Cerca nei miei file"
        ],
        [
          "account.fields.signaturePassword",
          "Mot de passe signature",
          "Signature password",
          "Signatur Passwort",
          "Senha de assinatura",
          "Pasfhocal sínithe",
          "Password di firma",
          "Password della firma"
        ],
        [
          "account.fields.username",
          "Nom d'utilisateur",
          "User name",
          "Nutzername",
          "nome de usuário",
          "ainm úsáideora",
          "nome d'utilizatore",
          "nome utente"
        ],
        [
          "account.messages.passwordChanged",
          "Mot de passe changé",
          "Password changed",
          "Passwort geändert",
          "Senha alterada",
          "Athraíodh an pasfhocal",
          "Password cambiata",
          "Password cambiata"
        ],
        [
          "account.messages.passwordNotUpdated",
          "Mot de passe non enregistré",
          "Password not updated",
          "Passwort nicht gespeichert",
          "Senha não salva",
          "Níor sábháladh pasfhocal",
          "Password micca salvata",
          "Password non salvata"
        ],
        [
          "account.messages.passwordUpdated",
          "Mot de passe enregistré",
          "Password updated",
          "Passwort gespeichert",
          "Senha salva",
          "Pasfhocal sábháilte",
          "Password salvata",
          "Password salvata"
        ],
        [
          "account.messages.photoDeleted",
          "Votre photo de profil a bien été supprimée,",
          "Your profile picture has been deleted",
          "Ihr Profilbild wurde gelöscht",
          "Sua foto de perfil foi excluída",
          "Scriosadh do phictiúr próifíle",
          "U vostru ritrattu di prufilu hè statu sguassatu",
          "La tua immagine del profilo è stata eliminata"
        ],
        [
          "account.messages.photoNotDeleted",
          "Votre photo de profil n'a pas été supprimée",
          "Your profile picture has not been deleted",
          "Ihr Profilbild wurde nicht gelöscht",
          "Sua foto de perfil não foi excluída",
          "Níor scriosadh do phictiúr próifíle",
          "U vostru ritrattu di prufilu ùn hè micca statu sguassatu",
          "La tua immagine del profilo non è stata cancellata"
        ],
        [
          "account.messages.profileNotUpdated",
          "Informations personnelles non enregistrées",
          "Personal data not updated",
          "Nicht registrierte persönliche Informationen",
          "Informações pessoais não registradas",
          "Faisnéis phearsanta neamhchláraithe",
          "Infurmazione persunale micca arregistrata",
          "Informazioni personali non registrate"
        ],
        [
          "account.messages.profileUpdated",
          "Informations personnelles enregistrées",
          "Personal data updated",
          "Persönliche Informationen gespeichert",
          "Informações pessoais armazenadas",
          "Faisnéis phearsanta stóráilte",
          "Infurmazione persunale almacenata",
          "Informazioni personali memorizzate"
        ],
        [
          "account.messages.signatureDeleted",
          "Votre signature a bien été supprimée,",
          "Your signature has been deleted",
          "Ihre Unterschrift wurde gelöscht",
          "Sua assinatura foi deletada",
          "Scriosadh do shíniú",
          "A vostra firma hè stata sguassata",
          "La tua firma è stata cancellata"
        ],
        [
          "account.messages.signatureNotDeleted",
          "Votre signature n'a pas été supprimée,",
          "Your signature has not been deleted",
          "Ihre Unterschrift wurde nicht gelöscht",
          "Sua assinatura não foi excluída",
          "Níor scriosadh do shíniú",
          "A vostra firma ùn hè micca stata cancellata",
          "La tua firma non è stata cancellata"
        ],
        [
          "account.other.subscribeCommunication",
          "Recevoir les communications",
          "Receive communications",
          "Mitteilungen erhalten",
          "Receber comunicações",
          "Faigh cumarsáid",
          "Ricevi cumunicazioni",
          "Ricevi comunicazioni"
        ],
        [
          "account.other.subscribeNewsletter",
          "S'inscrire à la newsletter",
          "Subscribe to the newsletter",
          "Newsletter abonnieren",
          "Assine o boletim informativo",
          "Liostáil leis an nuachtlitir",
          "Abbonatevi à a newsletter",
          "Iscriviti alla Newsletter"
        ],
        [
          "account.title.communication",
          "Communication",
          "Communication",
          "Kommunikation",
          "Comunicação",
          "Cumarsáid",
          "Cumunicazione",
          "Comunicazione"
        ],
        [
          "account.title.myAccount",
          "Mon compte",
          "My account",
          "Mein Konto",
          "Minha conta",
          "Mo chuntas",
          "U mo contu",
          "Il mio account"
        ],
        [
          "account.title.other",
          "Divers",
          "Miscellaneous",
          "Verschiedene",
          "Vários",
          "Éagsúla",
          "Diversi",
          "Varie"
        ],
        [
          "account.title.personalInfo",
          "Informations personnelles",
          "Personal data",
          "Persönliche Informationen",
          "Informações pessoais",
          "Faisnéis phearsanta",
          "Infurmazioni persunali",
          "Informazioni personali"
        ],
        [
          "account.title.photo",
          "Photo",
          "Photo",
          "Foto",
          "foto",
          "grianghraf",
          "Foto",
          "Foto"
        ],
        [
          "account.title.security",
          "Sécurité et confidentialité",
          "Security and confidentiality",
          "Sicherheit und Vertraulichkeit",
          "Segurança e confidencialidade",
          "Slándáil agus rúndacht",
          "Sicurezza è riservatezza",
          "Sicurezza e riservatezza"
        ],
        [
          "account.title.signature",
          "Signature",
          "Signature",
          "Unterschrift",
          "Assinatura",
          "Síniú",
          "Firma",
          "Firma"
        ],
        [
          "actions.add",
          "Ajouter",
          "Add",
          "Hinzufügen",
          "Adicionar",
          "Cuir leis",
          "Aghjunghje",
          "Inserisci"
        ],
        [
          "actions.back",
          "Retour",
          "Back",
          "Rückkehr",
          "Retornar",
          "Fill ar ais",
          "Ritornu",
          "Ritorno"
        ],
        [
          "actions.clear",
          "Retirer",
          "Clear",
          "Entfernen",
          "Remover",
          "Bain",
          "Cacciate",
          "Rimuovere"
        ],
        [
          "actions.close",
          "Fermer",
          "Close",
          "Schließen",
          "Fechar",
          "Dún",
          "Da chjude",
          "Chiudere"
        ],
        [
          "actions.customize",
          "Personnaliser",
          "Personalize",
          "Personifizieren",
          "Personalizar",
          "Pearsanú",
          "Personalizà",
          "Personalizzare"
        ],
        [
          "actions.delete",
          "Supprimer",
          "Delete",
          "Entfernen",
          "Remover",
          "Bain",
          "Cacciate",
          "Rimuovere"
        ],
        [
          "actions.deleting",
          "Suppression",
          "Deletion",
          "Streichung",
          "Eliminação",
          "Scriosadh",
          "Eliminazione",
          "Cancellazione"
        ],
        [
          "actions.save",
          "Enregistrer",
          "Save",
          "sparen",
          "Salvar",
          "Sábháil",
          "Salvà",
          "registrare"
        ],
        [
          "actions.seeEnglish",
          "Voir en anglais",
          "Display in English",
          "Ansicht in Englisch",
          "Ver em ingles",
          "Amharc i mBéarla",
          "Vede in inglese",
          "Visualizza in inglese"
        ],
        [
          "actions.seeFrench",
          "Voir en françaiS",
          "Display in French",
          "Siehe auf Englisch",
          "Veja em ingles",
          "Féach i mBéarla",
          "Vede in inglese",
          "Vedi in inglese"
        ],
        [
          "app.404.back",
          "Revenir à l'accueil",
          "Back to the Home page",
          "Nach Hause zurückkehren",
          "Voltar para casa",
          "Fill abhaile",
          "Vultà in casa",
          "Torna a casa"
        ],
        [
          "app.404.title",
          "Oups, cette page ne semble pas exister",
          "Oops, the page does not seem to exist",
          "Hoppla, diese Seite scheint nicht zu existieren",
          "Ops, esta página parece não existir",
          "Úps, ní cosúil go bhfuil an leathanach seo ann",
          "Oops, sta pagina ùn pare micca esiste",
          "Ops, questa pagina sembra non esistere"
        ],
        [
          "app.login.resetPassword.backToLogin",
          "Retourner au formulaire de connexion",
          "Return to the connection form",
          "Kehren Sie zum Anmeldeformular zurück",
          "Voltar ao formulário de login",
          "Fill ar ais ar an bhfoirm logála isteach",
          "Ritorna à u modulu di cunnessione",
          "Torna al modulo di accesso"
        ],
        [
          "app.login.resetPassword.email.conform",
          "L'adresse mail renseignée n'est pas conforme",
          "The email address provided is not compliant",
          "Die angegebene E-Mail-Adresse ist nicht korrekt",
          "O endereço de e-mail fornecido não está correto",
          "Níl an seoladh ríomhphoist a cuireadh ar fáil ceart",
          "L'indirizzu email furnitu ùn hè micca currettu",
          "L'indirizzo e-mail fornito non è corretto"
        ],
        [
          "app.login.resetPassword.email.empty",
          "Veuillez renseigner votre adresse email",
          "Please enter your email address",
          "Geben Sie bitte Ihre Email-Adresse ein",
          "Por favor, indique o seu endereço de e-mail",
          "Iontráil do sheoladh ríomhphoist le do thoil",
          "Inserite puru u vostru indirizzu email",
          "Inserisci il tuo indirizzo email"
        ],
        [
          "app.login.resetPassword.email.missing",
          "Aucun compte associé à cette adresse email",
          "No account associated to this email address",
          "Kein Konto mit dieser E-Mail-Adresse verknüpft",
          "Nenhuma conta associada a este endereço de e-mail",
          "Níl aon chuntas bainteach leis an seoladh ríomhphoist seo",
          "Nisun contu assuciatu à questu indirizzu email",
          "Nessun account associato a questo indirizzo email"
        ],
        [
          "app.login.resetPassword.email.success",
          "Veuillez renseigner votre adresse email",
          "Please enter your email address",
          "Geben Sie bitte Ihre Email-Adresse ein",
          "Por favor, indique o seu endereço de e-mail",
          "Iontráil do sheoladh ríomhphoist le do thoil",
          "Inserite puru u vostru indirizzu email",
          "Inserisci il tuo indirizzo email"
        ],
        [
          "app.login.resetPassword.forgottenPasswordDescription",
          "Veuillez renseigner l'adresse email associée à votre compte, nous vous enverrons un lien de confirmation de réinitialisation",
          "Enter email address attached to your account, we will return a reset confirmation link",
          "Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein. Wir senden Ihnen dann einen Link zum Bestätigen des Zurücksetzens",
          "Por favor, insira o endereço de e-mail associado à sua conta, nós lhe enviaremos um link de confirmação de redefinição",
          "Iontráil an seoladh ríomhphoist a bhaineann le do chuntas, seolfaimid nasc dearbhaithe athshocraithe chugat",
          "Inserite l'indirizzu email assuciatu à u vostru contu, vi invieremu un ligame di cunferma di resettatura",
          "Inserisci l'indirizzo email associato al tuo account, ti invieremo un link di conferma del ripristino"
        ],
        [
          "app.login.resetPassword.forgottenPasswordLink",
          "Mot de passe oublié ?",
          "Forgotten password?",
          "Passwort vergessen?",
          "Esqueceu sua senha?",
          "Dearmad ar pasfhocal?",
          "Dimenticatu a chjave d'intesa?",
          "Ha dimenticato la password?"
        ],
        [
          "app.login.resetPassword.forgottenPasswordTitle",
          "Mot de passe oublié",
          "Forgotten password",
          "Passwort vergessen",
          "Esqueceu sua senha",
          "Dearmad ar pasfhocal",
          "Dimenticatu a chjave d'intesa",
          "Ha dimenticato la password"
        ],
        [
          "app.login.resetPassword.mailSent",
          "Un email de confirmation de réinitialisation de mot de passe à été envoyé.",
          "The password reset confirmation email has been sent",
          "Eine Bestätigungs-E-Mail zum Zurücksetzen des Passworts wurde gesendet.",
          "Um e-mail de confirmação de redefinição de senha foi enviado.",
          "Seol r-phost dearbhaithe athshocraithe pasfhocal.",
          "Un email di cunfirmazione di resettore di a password mandatu.",
          "È stata inviata un'e-mail di conferma della reimpostazione della password."
        ],
        [
          "app.login.resetPassword.mailSentPassword",
          "Un email avec vos nouveaux identifiants vous a été envoyé. Pensez bien à le changer une fois connecté.",
          "An email with new IDs was sent. Please update once connected",
          "Eine E-Mail mit Ihren neuen Kennungen wurde an Sie gesendet. Denken Sie daran, es zu ändern, sobald die Verbindung hergestellt ist.",
          "Um e-mail com seus novos identificadores foi enviado para você. Lembre-se de alterá-lo depois de conectado.",
          "Tá ríomhphost le d’aitheantóirí nua seolta chugat. Cuimhnigh é a athrú nuair a bheidh sé ceangailte.",
          "Un email cù i vostri novi identificatori vi hè statu mandatu. Ricurdate di cambiallu una volta cunnessu.",
          "Ti è stata inviata un'e-mail con i tuoi nuovi identificatori. Ricordati di cambiarlo una volta connesso."
        ],
        [
          "app.login.resetPassword.resetPassword",
          "Réinitialiser",
          "Reset",
          "R initialisieren",
          "R inicializar",
          "R tosaigh",
          "R inizializà",
          "R inizializza"
        ],
        [
          "app.login.responses.forbiddenSignatureExtension",
          "Extension de la signature interdite",
          "Extension of signature prohibited",
          "Signaturverlängerung verboten",
          "Extensão de assinatura proibida",
          "Toirmisctear síneadh sínithe",
          "Estensione di firma pruibita",
          "Estensione della firma vietata"
        ],
        [
          "app.login.responses.inactiveAccount",
          "Compte inactif / ou plus autorisé à se connecter",
          "Inactive account / no longer authorized to connect",
          "Inaktives Konto / oder nicht mehr berechtigt, eine Verbindung herzustellen",
          "Conta inativa / ou não mais autorizada a conectar",
          "Cuntas neamhghníomhach / nó nach bhfuil údaraithe a thuilleadh a nascadh",
          "Contu inattivu / o ùn hè più autorizatu à cunnette vi",
          "Account inattivo / o non più autorizzato a connettersi"
        ],
        [
          "app.login.responses.invalidAccount",
          "Nom de compte ou mot de passe incorrect",
          "Invalid account or password",
          "Falscher Kontoname oder falsches Passwort",
          "Nome de conta ou senha incorreta",
          "Ainm cuntais nó pasfhocal mícheart",
          "Nome o password incorrettu",
          "Nome account o password errati"
        ],
        [
          "app.login.responses.invalidOrExpiredToken",
          "Token invalide ou expiré",
          "Invalid or expired token",
          "Token ungültig oder abgelaufen",
          "Token inválido ou expirado",
          "Token neamhbhailí nó imithe in éag",
          "Gettone invalidu o scadutu",
          "Token non valido o scaduto"
        ],
        [
          "app.login.responses.invalidSignatureImage",
          "Image de la signature invalide, le fichier sélectionné n'est pas une image",
          "Picture of signature is invalid ; the selected file is not a picture",
          "Bild der ungültigen Signatur, die ausgewählte Datei ist kein Bild",
          "Imagem da assinatura inválida, o arquivo selecionado não é uma imagem",
          "Íomhá den síniú neamhbhailí, ní íomhá é an comhad roghnaithe",
          "Immagine di a firma invalida, u fugliale selezziunatu ùn hè micca una maghjina",
          "Immagine della firma non valida, il file selezionato non è un'immagine"
        ],
        [
          "app.login.responses.ipNotAllowed",
          "Adresse ip transmise non autorisée à se connecter (restriction ip des utilisateurs)",
          "The IP sent is not authorized to connect (users' IP restriction)",
          "Übertragene IP-Adresse darf keine Verbindung herstellen (Benutzer-IP-Einschränkung)",
          "Endereço IP transmitido sem permissão para conexão (restrição de IP do usuário)",
          "Ní cheadaítear seoladh IP a tharchuirtear a nascadh (srian ip úsáideora)",
          "L'indirizzu IP trasmessu ùn hè micca permessu di cunnessione (restrizzione ip d'utilizatore)",
          "Indirizzo IP trasmesso non consentito per la connessione (restrizione ip dell'utente)"
        ],
        [
          "app.login.responses.noSignatureFound",
          "Signature introuvable ou inexistante",
          "Unexisting or signature not found",
          "Signatur nicht gefunden oder nicht vorhanden",
          "Assinatura não encontrada ou não existe",
          "Níor aimsíodh síniú nó níl sí ann",
          "Firma micca truvata o ùn esiste micca",
          "Firma non trovata o non esiste"
        ],
        [
          "app.login.responses.noTokenReceived",
          "Pas de token reçu",
          "No token received",
          "Kein Token empfangen",
          "Nenhum token recebido",
          "Ní bhfuarthas comhartha ar bith",
          "Nisun token ricevutu",
          "Nessun token ricevuto"
        ],
        [
          "app.login.responses.passwordNotChangedInTime",
          "Mot de passe non changé à temps (périodicité + délais max)",
          "Password not updated on time (periodicity + max delays)",
          "Zeit des Passworts nicht geändert (Periodizität + maximale Zeit)",
          "Tempo não alterado de senha (periodicidade + tempo máximo)",
          "Níor athraíodh pasfhocal am (tréimhsiúlacht + uas-am)",
          "Tempu di password micca cambiatu (periodicità + tempu massimu)",
          "Tempo di non modifica della password (periodicità + tempo massimo)"
        ],
        [
          "app.login.responses.unableToDeleteSignature",
          "Impossibilité de supprimer la signature",
          "Unable to delete the signature",
          "Unfähigkeit, die Signatur zu löschen",
          "Incapacidade de deletar a assinatura",
          "Ní féidir an síniú a scriosadh",
          "Incapacità di sguassà a firma",
          "Impossibilità di cancellare la firma"
        ],
        [
          "app.login.responses.unableToSaveSignature",
          "Impossibilité de sauvegarder la signature",
          "Unable to save the signature",
          "Unfähigkeit, die Signatur zu speichern",
          "Incapacidade de salvar a assinatura",
          "Neamhábaltacht an síniú a shábháil",
          "Incapacità di salvà a firma",
          "Impossibilità di salvare la firma"
        ],
        [
          "app.login.responses.unableToUpdateSignaturePassword",
          "Impossibilité de modifier le mot de passe de la signature",
          "Unable to update the signature password",
          "Das Signaturkennwort kann nicht geändert werden",
          "Não é possível alterar a senha da assinatura",
          "Ní féidir an focal faire sínithe a athrú",
          "Impussibule di cambià a password di firma",
          "Impossibile modificare la password della firma"
        ],
        [
          "app.login.responses.unableToUpdateUser",
          "Impossibilité de modifier l'utilisateur",
          "Unable to update user",
          "Benutzer kann nicht geändert werden",
          "Não é possível modificar o usuário",
          "Ní féidir an t-úsáideoir a mhodhnú",
          "Impussibule di mudificà l'utilizatore",
          "Impossibile modificare l'utente"
        ],
        [
          "app.login.responses.unableToUploadSignature",
          "Erreur lors de la transmission du fichier de la signature",
          "Error while uploading the signature file",
          "Fehler beim Übertragen der Signaturdatei",
          "Erro ao transmitir arquivo de assinatura",
          "Earráid agus an comhad sínithe á tharchur",
          "Errore durante a trasmissione di u schedariu di firma",
          "Errore durante la trasmissione del file della firma"
        ],
        [
          "app.login.responses.unavailable",
          "Le service d'authentification ne répond pas, veuillez contactez le support de planete-online",
          "The authentication service is not responding, please contact planete-online support",
          "Der Authentifizierungsdienst antwortet nicht. Bitte wenden Sie sich an den Planete-Online-Support",
          "O serviço de autenticação não responde, entre em contato com o suporte online da planete",
          "Ní fhreagraíonn an tseirbhís fíordheimhnithe, téigh i dteagmháil le do thoil le tacaíocht ar líne ar phláinéid",
          "U serviziu di autentificazione ùn risponde micca, per piacè cuntattate l'assistenza planete-online",
          "Il servizio di autenticazione non risponde, contatta il supporto di planete-online"
        ],
        [
          "app.login.responses.username",
          "Nom de compte ou mot de passe incorrect",
          "Invalid account or password",
          "Falscher Kontoname oder falsches Passwort",
          "Nome de conta ou senha incorreta",
          "Ainm cuntais nó pasfhocal mícheart",
          "Nome o password incorrettu",
          "Nome account o password errati"
        ],
        [
          "app.login.responsesnoTokenUserFound",
          "Pas d'utilisateur correspondant à celui embarqué dans le token",
          "No user corresponding to the one embedded in the token",
          "Kein Benutzer, der dem im Token eingebetteten Benutzer entspricht",
          "Nenhum usuário correspondente ao incorporado no token",
          "Gan aon úsáideoir a fhreagraíonn don cheann atá leabaithe sa chomhartha",
          "Nisun utilizatore chì currisponde à quellu incrustatu in u gettone",
          "Nessun utente corrispondente a quello incorporato nel token"
        ],
        [
          "app.presentation.text",
          "Regardez la présentation de votre nouvel outil quotidien : planete-online",
          "Look at the presentation of your new daily tool: planete-online",
          "Sehen Sie sich die Präsentation Ihres neuen täglichen Tools an: planete-online",
          "Veja a apresentação da sua nova ferramenta diária: planete-online",
          "Féach ar chur i láthair d’uirlis laethúil nua: planete-online",
          "Fighjate a presentazione di u vostru novu strumentu cutidianu: planete-online",
          "Guarda la presentazione del tuo nuovo strumento quotidiano: planete-online"
        ],
        [
          "app.presentation.watch",
          "Regarder",
          "Look",
          "Sehen",
          "Ver",
          "Bí ag faire",
          "Fighjulà",
          "Guardare"
        ],
        [
          "home.background.backgroundDeleted",
          "Le fond d'écran a bien été supprimé",
          "The wallpaper has been deleted",
          "Das Hintergrundbild wurde entfernt",
          "O papel de parede foi removido",
          "Baineadh an páipéar balla",
          "U wallpaper hè statu cacciatu",
          "Lo sfondo è stato rimosso"
        ],
        [
          "home.background.backgroundNotDeleted",
          "Le fond d'écran n'a pas été supprimé",
          "The wallpaper has not been deleted",
          "Das Hintergrundbild wurde nicht entfernt",
          "O papel de parede não foi removido",
          "Níor baineadh an páipéar balla",
          "U carta da parati ùn hè micca stata rimossa",
          "Lo sfondo non è stato rimosso"
        ],
        [
          "home.background.noBackground",
          "Aucun fond d'écran",
          "No wallpaper",
          "Keine Tapete",
          "Nenhum papel de parede",
          "Gan páipéar balla",
          "Senza carta da parati",
          "Nessuno sfondo"
        ],
        [
          "home.login.forgottenPassword",
          "Mot de passe oublié ?",
          "Forgotten password?",
          "Passwort vergessen?",
          "Esqueceu sua senha?",
          "Dearmad ar pasfhocal?",
          "Dimenticatu a chjave d'intesa?",
          "Ha dimenticato la password?"
        ],
        [
          "home.login.login",
          "Connexion,",
          "Connection",
          "Einloggen",
          "Conecte-se",
          "Logáil isteach",
          "Log in",
          "Accesso"
        ],
        [
          "home.login.logingOut",
          "Déconnexion en cours veuillez patienter",
          "Disconnection in progress, please wait",
          "D Verbindung läuft, bitte warten",
          "Conexão D em andamento, por favor aguarde",
          "D nasc idir lámha fan le do thoil",
          "Cunnessione D in corsu, aspittà",
          "D connessione in corso attendere prego"
        ],
        [
          "home.login.logout",
          "Se déconnecter",
          "Disconnect",
          "Einloggen",
          "Conecte-se",
          "Logáil isteach",
          "Log in",
          "Accesso"
        ],
        [
          "home.login.mailAddress",
          "Adresse e-mail",
          "Email address",
          "E-Mail-Adresse",
          "Endereço de email",
          "Seoladh ríomhphoist",
          "Indirizzu mail",
          "Indirizzo email"
        ],
        [
          "home.login.password",
          "Mot de passe",
          "Password",
          "Passwort",
          "Senha",
          "Pasfhocal",
          "Codice",
          "Parola d'ordine"
        ],
        [
          "home.login.stayLogged",
          "Rester connecté",
          "Stay connected",
          "In Verbindung bleiben",
          "Permaneça conectado",
          "Fan ceangailte",
          "Stà cunnessu",
          "Rimani connesso"
        ],
        [
          "home.login.toLogin",
          "Se connecter",
          "Connect",
          "Einloggen",
          "Entrar",
          "Le logáil isteach",
          "Per accede",
          "Per accedere"
        ],
        [
          "home.login.unableToDisconnect",
          "Impossible de déconnecter. Veuillez contactez l'assistance planete-online.",
          "Disconnection impossible, please contact panete-online support",
          "Verbindung konnte nicht hergestellt werden. Bitte wenden Sie sich an den planete-online Support.",
          "Incapaz de conectar. Entre em contato com o suporte online do planete.",
          "Ní féidir ceangal a dhéanamh. Téigh i dteagmháil le do thoil le tacaíocht ar líne ar phláinéid.",
          "Impussibule di cunnette vi. Per piacè cuntattate l'assistenza planete-online.",
          "Impossibile connetersi. Si prega di contattare il supporto in linea di planete."
        ],
        [
          "home.login.username",
          "Nom d'utilisateur",
          "User name",
          "Nutzername",
          "nome de usuário",
          "ainm úsáideora",
          "nome d'utilizatore",
          "nome utente"
        ],
        [
          "home.subtitle",
          "Connectez-vous pour accéder au logiciel planete-online, l'outil de gestion global de vos activités au quotidien",
          "Log in to access the planete-online software, the global management tool for your daily activities",
          "Melden Sie sich an, um auf die planete-online-Software zuzugreifen, das globale Verwaltungstool für Ihre täglichen Aktivitäten",
          "Faça login para acessar o software planete-online, a ferramenta de gerenciamento global para suas atividades diárias",
          "Logáil isteach chun rochtain a fháil ar na bogearraí ar líne ar phláinéid, an uirlis bainistíochta domhanda do do ghníomhaíochtaí laethúla",
          "Cunnettatevi per accede à u software planete-online, u strumentu di gestione glubale per e vostre attività cutidiane",
          "Effettua il login per accedere al software planete-online, lo strumento di gestione globale delle tue attività quotidiane"
        ],
        [
          "home.title",
          "Votre outil de gestion",
          "Your management tool",
          "Ihr Management-Tool",
          "Sua ferramenta de gestão",
          "Do uirlis bhainistíochta",
          "U vostru strumentu di gestione",
          "Il tuo strumento di gestione"
        ],
        [
          "menus.about",
          "À propos",
          "About..",
          "Über",
          "cerca de",
          "faoi",
          "circa",
          "di"
        ],
        [
          "menus.admin",
          "Administration",
          "Administration",
          "Verwaltung",
          "Administração",
          "Riarachán",
          "Amministrazione",
          "Amministrazione"
        ],
        [
          "menus.calendar",
          "Agenda",
          "Agenda",
          "Agenda",
          "Agenda",
          "Clár Oibre",
          "Agenda",
          "Agenda"
        ],
        [
          "menus.cancel",
          "Annuler",
          "Cancel",
          "Abbrechen",
          "Cancelar",
          "A chealú",
          "Per annullà",
          "Per cancellare"
        ],
        [
          "menus.contact",
          "Contact",
          "Contact",
          "Kontakt",
          "Contato",
          "Déan teagmháil",
          "Cuntattu",
          "Contatto"
        ],
        [
          "menus.help",
          "Assistance",
          "Support",
          "Hilfe",
          "Assistência",
          "Cúnamh",
          "Assistenza",
          "Assistenza"
        ],
        [
          "menus.home",
          "Accueil",
          "Home page",
          "Rezeption",
          "Recepção",
          "Fáiltiú",
          "Accolta",
          "accoglienza"
        ],
        [
          "menus.hotline",
          "Fiches liaisons",
          "Liaison forms",
          "Verbindungsblätter",
          "Folhas de conexão",
          "Bileoga ceangail",
          "Fogli di cunnessione",
          "Fogli di collegamento"
        ],
        [
          "menus.interfaceSettings",
          "Gestion interface",
          "Interface management",
          "Schnittstellenverwaltung",
          "Gerenciamento de interface",
          "Bainistíocht comhéadain",
          "Gestione di l'interfaccia",
          "Gestione dell'interfaccia"
        ],
        [
          "menus.logout",
          "Déconnexion",
          "Disconnection",
          "D Verbindung",
          "Conexão D",
          "D nasc",
          "Cunnessione D.",
          "Collegamento D."
        ],
        [
          "menus.modules",
          "Modules",
          "Modules",
          "Module",
          "Módulos",
          "Modúil",
          "Moduli",
          "Moduli"
        ],
        [
          "menus.myAccount",
          "Mon compte",
          "My account",
          "Mein Konto",
          "Minha conta",
          "Mo chuntas",
          "U mo contu",
          "Il mio account"
        ],
        [
          "menus.planeteOnlineNews",
          "Actualités planete-online",
          "planete-online flash news",
          "Planet-Online-Nachrichten",
          "Notícias do planeta online",
          "Nuacht ar líne an phláinéid",
          "Attualità in linea pianeta",
          "Notizie dal pianeta online"
        ],
        [
          "menus.planeteOnlineNewsCreation",
          "Communication",
          "Communication",
          "Kommunikation",
          "Comunicação",
          "Cumarsáid",
          "Cumunicazione",
          "Comunicazione"
        ],
        [
          "widgets.agenda.fields.addDescription",
          "Entrez une description",
          "Enter a description",
          "Geben Sie eine Beschreibung ein",
          "Insira uma descrição",
          "Iontráil tuairisc",
          "Inserite una descrizzione",
          "Immettere una descrizione"
        ],
        [
          "widgets.agenda.fields.addEvent",
          "Ajouter un événement",
          "Add an event",
          "Fügen Sie ein Ereignis hinzu",
          "Adicionar um evento",
          "Cuir imeacht leis",
          "Aghjunghje un avvenimentu",
          "Aggiungi un evento"
        ],
        [
          "widgets.agenda.fields.confirmDelete",
          "Êtes-vous sûr(e) du bien vouloir supprimer cet événement ?",
          "Are you sure to delete this event?",
          "Möchten Sie dieses Ereignis wirklich löschen?",
          "tem certeza que deseja deletar este evento?",
          "an bhfuil tú cinnte gur mhaith leat an ócáid seo a scriosadh?",
          "Site sicuru di vulè sguassà st'evenimentu?",
          "sei sicuro di voler cancellare questo evento?"
        ],
        [
          "widgets.agenda.fields.endDate",
          "Date de fin",
          "End date",
          "Endtermin",
          "Data final",
          "Dáta deiridh",
          "Data di fine",
          "Data di fine"
        ],
        [
          "widgets.agenda.fields.noDescription",
          "Aucune description,",
          "No description",
          "Keine Beschreibung",
          "Sem descrição",
          "Gan tuairisc",
          "Nisuna descrizzione",
          "Nessuna descrizione"
        ],
        [
          "widgets.agenda.fields.startDate",
          "Date de début",
          "Start date",
          "Zieldatum",
          "Data da meta",
          "Dáta sprice",
          "Data di u scopu",
          "Data obiettivo"
        ],
        [
          "widgets.agenda.fields.title",
          "Titre",
          "Title",
          "Titel",
          "Título",
          "Teideal",
          "Titulu",
          "Titolo"
        ],
        [
          "widgets.agenda.periods.day",
          "Jour",
          "Day",
          "Tag",
          "Dia",
          "Lá",
          "Ghjornu",
          "Giorno"
        ],
        [
          "widgets.agenda.periods.month",
          "Mois",
          "Month",
          "Monat",
          "Mês",
          "Mí",
          "Mese",
          "Mese"
        ],
        [
          "widgets.agenda.periods.week",
          "Semaine",
          "Week",
          "Woche",
          "Semana",
          "Seachtain",
          "Settimana",
          "Settimana"
        ],
        [
          "widgets.modules.name",
          "Modules",
          "Modules",
          "Module",
          "Módulos",
          "Modúil",
          "Moduli",
          "Moduli"
        ],
        [
          "widgets.modules.noModules",
          "Aucun module favoris",
          "No favorite module(s)",
          "Kein Lieblingsmodul",
          "Nenhum módulo favorito",
          "Sin modúl is fearr leat",
          "Nisun modulu preferitu",
          "Nessun modulo preferito"
        ],
        [
          "widgets.modules.types.recurrent",
          "Fréquents",
          "Reccurent",
          "Häufig",
          "Fre quent",
          "Go minic",
          "Fre quent",
          "Frequente"
        ],
        [
          "widgets.modules.types.starred",
          "Favoris",
          "Favorite",
          "Favoriten",
          "Favoritos",
          "Favorites",
          "Favuriti",
          "Preferiti"
        ],
        [
          "widgets.planeteOnlineNews.name",
          "Planete-online news",
          "planete-online flash news",
          "Planet-Online-Nachrichten",
          "Notícias do planeta online",
          "Nuacht ar líne an phláinéid",
          "Attualità in linea pianeta",
          "Notizie dal pianeta online"
        ],
        [
          "widgets.planeteOnlineNews.seeMore",
          "Lire la suite",
          "Read more",
          "Weiterlesen",
          "Consulte Mais informação",
          "Leigh Nios mo",
          "Leghje più",
          "Leggi di più"
        ],
        [
          "widgets.tasks.actions.hideCompletedTasks",
          "Cacher les tâches complétées,",
          "Hide completed tasks",
          "Verstecke komplette Aufgaben",
          "Esconder tarefas completas",
          "Folaigh tascanna iomlána",
          "Oculta i compiti cumpleti",
          "Nascondi attività complete"
        ],
        [
          "widgets.tasks.actions.showCompletedTasks",
          "Afficher les tâches complétées",
          "Display completed tasks",
          "Vollständige Aufgaben anzeigen",
          "Mostrar tarefas completas",
          "Taispeáin tascanna iomlána",
          "Mustrà compiti cumpleti",
          "Mostra attività complete"
        ],
        [
          "widgets.tasks.addTask",
          "Ajouter une tâche",
          "Add a task",
          "Fügen Sie eine Aufgabe hinzu",
          "Adicionar uma tarefa",
          "Cuir tasc leis",
          "Aghjunghje un compitu",
          "Aggiungi un'attività"
        ],
        [
          "widgets.tasks.fields.title",
          "Titre",
          "Title",
          "Titel",
          "Título",
          "Teideal",
          "Titulu",
          "Titolo"
        ],
        [
          "widgets.tasks.name",
          "Tâches",
          "Tasks",
          "Aufgaben",
          "Tarefas",
          "Tascanna",
          "Compiti",
          "Compiti"
        ],
        [
          "widgets.tasks.noTasks",
          "Aucune tâche,",
          "No task",
          "Nicht fragen",
          "Não pergunte",
          "Sin tasc",
          "Nisun compitu",
          "Non chiedere"
        ],
        [
          "wigets.agenda.name",
          "Agenda",
          "Agenda",
          "Agenda",
          "Agenda",
          "Clár Oibre",
          "Agenda",
          "Agenda"
        ]
      ],
      assocKeys: {
        "CLEF": "key",
        "FRANCAIS": "fr",
        "ANGLAIS": "en",
        "ALLEMAND": "de",
        "PORTUGAIS": "pt",
        "NEERLANDAIS": "nl",
        "CORSE": "fr-CO",
        "ITALIEN": "it"
      }
    }
  },
  methods: {
    assign(obj, keyPath, value) {
      let lastKeyIndex = keyPath.length-1;
      for (let i = 0; i < lastKeyIndex; ++ i) {
        let key = keyPath[i];
        if (!(key in obj)){
          obj[key] = {}
        }
        obj = obj[key];
      }
      obj[keyPath[lastKeyIndex]] = value;
    },
    createJSON() {
      let keys = this.trad.shift();


      let allLangs = []

      Object.values(this.assocKeys).forEach((langKey, index) => {
        let messages = {
          lang: langKey,
          messages: {}
        }

        this.trad.forEach(terms => {
          let key = terms[0]
          let trad = terms[index]

          let splitted = key.split(".")
          this.assign(messages.messages, splitted, trad)
        })

        allLangs.push(messages)
      })

      for (let message of allLangs) {
        this.saveFile(message.lang + ".json", JSON.stringify(message.messages))
      }

    },
    saveFile: function(filename, data) {
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
          a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
          e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          a.dispatchEvent(e);
    }
  }
}
</script>

<style scoped>

</style>